export const LoginStatus = {
  loginStatus: {
    loggedIn: "LOGGED_IN",
    loggingIn: "LOG_IN_PROGRESS",
    loggedOff: "LOGGED_OUT",
  },
};

// export const Policies = ["UPDATE_COMPANY", "UPDATE_DOCUMENT_TYPE", "SEARCH_BY_DESIGNATION", "DELETE_SKILL", "UPDATE_BASIC_INFO", "DELETE_LEAVE_TYPE", "VIEW_SKILLS", "DELETE_EMPLOYMENT_TYPE", "DELETE_DOCUMENT_TYPE", "ADD_DOCUMENT_TYPE", "GET_EMPLOYMENT_TYPE", "DELETE_COMPANY", "VIEW_REPORTING_MANAGERS", "SEARCH_BY_EMPLOYMENT_TYPE", "ADD_EMPLOYMENT_TYPE", "ADD_EMPLOYEE_ROLE", "VIEW_EMPLOYEE_DOCS", "ADD_EMPLOYEE", "SEARCH_BY_SKILL", "VIEW_WORK_EXPERIENCE", "VIEW_EMERGENCY_CONTACTS", "UPDATE_SKILL", "SEARCH_BY_REPORTING_MANAGER", "VIEW_EDUCATIONAL_QUALIFICATIONS", "SEARCH_BY_ALLOCATION", "UPDATE_LEAVE_TYPE", "ADD_LEAVE_TYPE", "VIEW_PERSONAL_INFO", "UPDATE_EMPLOYEE_ROLE", "UPDATE_EMPLOYMENT_TYPE", "SEARCH_BY_EXPERIENCE", "DELETE_EMPLOYEE_ROLE", "UPDATE_DESIGNATION"];
export const Policies = [];

export const LeaveStatus = [
  { value: "PENDING", label: "PENDING" },
  { value: "APPROVED", label: "APPROVED" },
  { value: "CANCELLED", label: "CANCELLED" },
  { value: "REJECTED", label: "REJECTED" },
  { value: "", label: "ALL" },
];

export const LeaveRequestAction = [
  { value: "PENDING", label: "PENDING" },
  { value: "APPROVED", label: "APPROVED" },
  { value: "REJECTED", label: "REJECTED" },
];

export const hourOptions = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
];

export const messages = {
  errorMessage:
    "Server is not responding at the moment. Please try again later",
  addAssetSuccess: "Asset added successfully",
  addEmployeeSuccess: "Employee added successfully",
  applyLeaveSuccess: "Applied leave successfully",
  addNewReportSuccess: "Status report sent successfully",
  assignLeaveSuccess: "Leave assigned successfully",
  entitleLeaveSuccess: "Leave entitled successfully",
  enitleNoDataMessage: "No employees are available to add the entitlement ",
  entitleLeaveDeleteSuccess: "Entitlement deleted successfully",
  submitAppraisalSuccess: "Appraisal submitted successfully",
  editAppraisalSuccess: "Appraisal edited successfully",
  completeAppraisalSuccess: "Appraisal completed successfully",
  postponeAppraisalSuccess: "Appraisal Postponed successfully",
  startYearValidationErrorMessage: "Start year must be lesser than end year",
  yearValidationErrorMessage: "End year must be greater than start year",
  deleteEmployeeSuccess: "Employee deleted successfully",
  acrossYearValidation: "Entitlement cannot be added across years",
};
