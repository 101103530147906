import clipboard from "../../../Utils/icons/clipboards.svg";
import creditCard from "../../../Utils/icons/people.svg";
import mortarboard from "../../../Utils/icons/mortarboard.svg";
import copy from "../../../Utils/icons/copy.svg";
import building from "../../../Utils/icons/building.svg";
import iconPhone from "../../../Utils/icons/phone-call.svg";
import layers from "../../../Utils/icons/layers.svg";

const profileList = [
  {
    title: "Reporting to",
    image: {
      src: clipboard,
    },
    reportingPersonnels: [],
    permission: "VIEW_REPORTING_MANAGERS",
  },
  {
    title: "Personal Info",
    image: {
      src: creditCard,
    },
    datarow: true,
    editLink: true,
    items: [],
    permission: "VIEW_PERSONAL_INFO",
  },
  {
    title: "Education",
    image: {
      src: mortarboard,
    },
    editLink: true,
    datarow: true,
    addOption: true,
    items: [],
    permission: "VIEW_EDUCATIONAL_QUALIFICATIONS",
  },
  {
    title: "Project Allocations",
    image: {
      src: copy,
    },
    items: [],
    permission: "VIEW_EMPLOYEE_DOCS",
  },
  {
    title: "Emergency Contacts",
    image: {
      src: iconPhone,
    },
    editLink: true,
    datarow: true,
    addOption: true,
    items: [],
    permission: "VIEW_EMERGENCY_CONTACTS",
  },
  {
    title: "Work Experience",
    image: {
      src: building,
    },
    editLink: true,
    datarow: true,
    addOption: true,
    items: [],
    permission: "VIEW_WORK_EXPERIENCE",
  },
  {
    title: "Skills",
    image: {
      src: layers,
    },
    editLink: true,
    datarow: false,
    addOption: true,
    items: [],
    permission: "VIEW_SKILLS",
  },
  {
    title: "Documents",
    image: {
      src: copy,
    },
    editLink: true,
    addOption: true,
    items: [],
    permission: "VIEW_EMPLOYEE_DOCS",
  },
];

export default profileList;
