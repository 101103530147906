import { combineReducers } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";
import profile from "./profileReducer";
import auth from "./authReducer";
import employees from "./employeeReducer";
import project from "./projectReducer";
// import leave from "./leaveReducer";

export default combineReducers({
  toastr: toastrReducer,
  profile,
  auth,
  employees,
  project,

  // leave
});
