import React, { lazy, Suspense } from "react";
import "whatwg-fetch";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ReduxToastr from "react-redux-toastr";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import "react-table/react-table.css";
import store from "./store/store";
import Loader from "./Utils/Loader";

const Home = lazy(() => import("./Home"));
const Login = lazy(() => import("./Login"));

const App = () => (
  <Provider store={store}>
    <>
      <Router>
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route path="/inside" component={Home} />
            <Route path="/" component={Login} />
          </Switch>
        </Suspense>
      </Router>
      <ReduxToastr
        timeOut={4000}
        newestOnTop
        preventDuplicates
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
      />
    </>
  </Provider>
);

export default App;
