import { ActionType } from "./../constants/ActionTypes";
import { LoginStatus } from "./../constants/StringConstants";

export default function reducer(
  state = {
    validAccessToken: false,
    accessToken: "",
    loginStatus: LoginStatus.loginStatus.loggedOff,
    policies: []
  },
  action
) {
  switch (action.type) {
    case ActionType.AuthActions.setAccessToken:
      return { ...state, accessToken: action.data };
    case ActionType.AuthActions.setAccessTokenValidity:
      return { ...state, validAccessToken: action.data };
    case ActionType.AuthActions.setLoginStatus:
      return { ...state, loginStatus: action.data };
    case ActionType.AuthActions.setAccessPolicies: 
      return {...state, policies: action.data};
    case ActionType.AuthActions.resetAccessPolicies: 
      return {...state, policies: action.data};
    default:
      return state;
  }
}
