import { ActionType } from "../constants/ActionTypes";

export default function reducer(
  state = {
    showDetailView: false,
    selectedProject: {},
    projectList: [],
    clientList: [],
  },
  action
) {
  switch (action.type) {
    case ActionType.ProjectActions.showDetailView:
      return { ...state, showDetailView: action.data };
    case ActionType.ProjectActions.projectList:
      return { ...state, projectList: action.data };
    case ActionType.ProjectActions.selectedProject:
      return { ...state, selectedProject: action.data };
    case ActionType.ProjectActions.clientList:
      return { ...state, clientList: action.data };
    default:
      return state;
  }
}
