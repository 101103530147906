import { ActionType } from "./../constants/ActionTypes";
import profileList from "../Home/Content/Profile/profileList";
import { cloneDeep } from "lodash";

let otherProfileList = cloneDeep(profileList);
export default function reducer(
  state = {
    showEditProfileModal: false,
    selectedCardForEdit: "",
    currentSelectedEmployeeProfile: "",
    allDocumentTypes: [],
    userProfile: "",
    profileList,
    otherProfileList,
    formData: null,
    addMode: false,
    profileImage: "",
    refetchProfile: false,
    otherProfileView: false,
    otherUserProfile: null,
  },
  action
) {
  switch (action.type) {
    case ActionType.ProfileActions.setProfilePic:
      return { ...state, profileImage: action.data };
    case ActionType.ProfileActions.showEditProfileModal:
      return { ...state, showEditProfileModal: action.data };
    case ActionType.ProfileActions.setSelectedCardForEdit:
      return { ...state, selectedCardForEdit: action.data };
    case ActionType.ProfileActions.setCurrentSelectedEmployeeProfile:
      return { ...state, currentSelectedEmployeeProfile: action.data };
    case ActionType.ProfileActions.setAllDocumentTypes:
      return { ...state, allDocumentTypes: action.data };
    case ActionType.ProfileActions.setUserProfile:
      return { ...state, userProfile: action.data };
    case ActionType.ProfileActions.setProfileList:
      return { ...state, profileList: action.data };
    case ActionType.ProfileActions.setOtherProfileList:
      return { ...state, otherProfileList: action.data };
    case ActionType.ProfileActions.setFormData:
      return { ...state, formData: action.data };
    case ActionType.ProfileActions.clearFormData:
      return { ...state, formData: null };
    case ActionType.ProfileActions.setAddMode:
      return { ...state, addMode: action.data };
    case ActionType.ProfileActions.refetchProfile:
      return { ...state, refetchProfile: action.data };
    case ActionType.ProfileActions.setOtherProfileView:
      return { ...state, otherProfileView: action.data };
    case ActionType.ProfileActions.setOtherUserProfile:
      return { ...state, otherUserProfile: action.data };
    case ActionType.ProfileActions.resetProfile:
      return {
        ...state,
        showEditProfileModal: false,
        selectedCardForEdit: "",
        currentSelectedEmployeeProfile: "",
        allDocumentTypes: [],
        userProfile: "",
        // profileList,
        formData: null,
        addMode: false,
        profileImage: "",
        refetchProfile: false,
        otherProfileView: false,
        otherUserProfile: null,
      };
    default:
      return state;
  }
}
