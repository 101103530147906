import React from "react";
import { css } from "@emotion/core";
import { PropagateLoader } from "react-spinners";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: flex;
`;

class Loader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }
  render() {
    return (
      <div className="loader">
        <PropagateLoader
          css={override}
          size={20}
          color={"#3b8251"}
          loading={this.state.loading}
        />
      </div>
    );
  }
}
export default Loader;
