export const ActionType = {
  ProfileActions: {
    setProfilePic: "SET_PROFILE_PIC",
    showEditProfileModal: "SHOW_EDIT_PROFILE_MODAL",
    setSelectedCardForEdit: "SET_SELECTED_CARD_FOR_PROFILE_EDIT",
    setCurrentSelectedEmployeeProfile: "SET_CURRENT_SELECTED_EMPLOYEE_PROFILE",
    setAllDocumentTypes: "SET_ALL_DOCUMENT_TYPES",
    setUserProfile: "SET_USER_PROFILE",
    setProfileList: "SET_PROFILE_LIST",
    setOtherProfileList: "SET_OTHER_PROFILE_LIST",
    setFormData: "SET_FORM_DATA",
    setAddMode: "SET_ADD_MODE",
    clearFormData: "CLEAR_FORM_DATA",
    refetchProfile: "REFETCH_PROFILE",
    setOtherProfileView: "SET_OTHER_PROFILE_VIEW",
    setOtherUserProfile: "SET_OTHER_USER_PROFILE",
    resetProfile: "RESET_PROFILE",
  },
  AuthActions: {
    setAccessToken: "SET_ACCESS_TOKEN",
    setAccessTokenValidity: "SET_ACCESS_TOKEN_VALIDITY",
    setLoginStatus: "SET_LOGIN_STATUS",
    setAccessPolicies: "SET_ACCESS_POLICIES",
    resetAccessPolicies: "RESET_ACCESS_POLICIES",
  },
  EmployeesActions: {
    setAllDesignations: "SET_ALL_DESIGNATIONS",
    setAllEmploymentTypes: "SET_ALL_EMPLOYMENT_TYPES",
    setAllRoles: "SET_ALL_ROLES",
    addToFetchedEmployees: "ADD_TO_FETCHED_EMPLOYEES",
    setAllSkills: "SET_ALL_SKILLS",
    refetchEmployeeList: "REFETCH_EMPLOYEE_LIST",
    setAllEmployeesList: "SET_ALL_EMPLOYEE_LIST",
  },
  ProjectActions: {
    showDetailView: "SHOW_DETAIL_VIEW",
    selectedProject: "SELECTED_PROJECT",
    projectList: "PROJECT_LIST",
    clientList: "SET_ALL_CLIENTS",
  },
};
