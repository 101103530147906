import { ActionType } from "./../constants/ActionTypes";

export default function reducer(
  state = {
    fetchedEmployees: [],
    allDesignations: [],
    allEmploymentTypes: [],
    allRoles: [],
    allSkills: [],
    refetchEmployeeList: false,
    allEmployeeList: [],
  },
  action
) {
  switch (action.type) {
    case ActionType.EmployeesActions.addToFetchedEmployees:
      return { ...state, fetchedEmployees: action.data };
    case ActionType.EmployeesActions.setAllDesignations:
      return { ...state, allDesignations: action.data };
    case ActionType.EmployeesActions.setAllEmploymentTypes:
      return {
        ...state,
        allEmploymentTypes: action.data.filter(employmentType => {
          return employmentType.employmentTypeName !== "Deleted";
        }),
      };
    case ActionType.EmployeesActions.setAllRoles:
      return { ...state, allRoles: action.data };
    case ActionType.EmployeesActions.setAllSkills:
      return { ...state, allSkills: action.data };
    case ActionType.EmployeesActions.refetchEmployeeList:
      return { ...state, refetchEmployeeList: action.data };
    case ActionType.EmployeesActions.setAllEmployeesList:
      return { ...state, allEmployees: action.data };

    default:
      return state;
  }
}
